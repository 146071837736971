import "./HowDoesItWorks.scss";

function HowDoesItWorks() {
  return (
    <section className="wrapper howDowsItWork__page">
      <h2>How does it work?</h2>
      <p>
      This ±MaisMenos± INDIVI-DUALISM SERIES auction will begin Wednesday 6 October at 7pm (GMT+1) on the official Ephemeral Ethernal website. All 4 NFTs will be released at the same time, and the auction will last for a total of 24 hours. Bids begin at 0,1 ETH. All NFTs will be launched through the Ephemeral Ethernal wallet. Powered by OpenSea.
      </p>
      <h2>What is it?</h2>
      <p>
      Ephemeral Ethernal is an NFT platform, including original works by a wide selection of contemporary artists articulating exceptionally poignant and unique creative voices. Emerging from the growing debate surrounding the materiality, perpetuity and inherent value of artworks, along with the very nature of art itself, it represents a provocation: plunging into the future and questioning the structures that support our concept and experience of modernity.
      <br />
      <br />
      Composed exclusively of NFT-based works, Ephemeral Ethernal fully embodies the spirit of this new technology, upending the rules of digital ownership and clearing the way towards the end of ephemeral art, evolving or even elevating it into a new plain of existence, akin to that of what was once understood as the ether: weightless, transparent, frictionless, undetectable chemically or physically, and literally permeating all matter and space.
      <br />
      <br />
      Functioning through an auction format, each release contains original NFT works by different artists.
      </p>
      <h2>Which artists have been involved so far?</h2>
      <p>
      ±MaisMenos± | AkaCorleone | Abdel Queta Tavares | Add Fuel | André da Loba | Blac Dwelle | Camila Nogueira | Cássio Markowski | Creyz | Confeere | Diogo Potes | DJ Ride vs Phries | Fábio Colaço | Felisberto | Fidel Évora | Fiumani | Fuzi | Gonçalo Mar | Halfstudio | Hélio Bray | Hibashira | JonOne | José Pando Lucas | Julien Raffin | Kruella D’Enfer | Luke Newton | Maria Imaginário | Nuno Alecrim | Pedrita Studio | Pedro Gramaxo | Pedro Podre | PIRESPOTES | Ruído | Tamara Alves | The Bonus | The Caver | Tiago Hesp | Vhils | Vulgo Axiom | Wasted Rita
      </p>
      <h2>What is an NFT and why should I get one?</h2>
      <p>
      NFTs (Non-Fungible Tokens) are pieces of digital content linked to the blockchain: the digital database underpinning cryptocurrencies such as Bitcoin and Ethereum. In the context of the art market, they are fundamentally changing the way people can collect and trade art, representing a step towards the democratization of art. Underpinned by a logic of access and transparency, they allow everyone to view each other’s collections, through their NFT wallets. Furthermore, as art is increasingly being regarded as a commodity that functions much as a form of stocks, the NFTs you buy today have the potential of greatly increasing in value in the future.
      </p>
      <h2>Non-Fungible: what does it mean?</h2>
      <p>
      If something is fungible it means that it is completely interchangeable. For example, if you lend someone a 5 euro bill and they pay you back later with a different 5 euro bill, the outcome is the same as both bills have the same value. In turn, a non-fungible asset is an item that is not interchangeable, like a plane ticket. All plane tickets are unique in that they are associated with different specific data, such as a name or a destination, meaning that each one is completely unique. In the case of NFTs, what this means is that they are unique and not mutually interchangeable, and so no two NFTs are the same.
      </p>
      <h2>Buying NFTs for the first time, how do I get started?</h2>
      <p>
      Get a{" "}
      <a
        href="https://metamask.io/"
        target="_blank"
        rel="noreferrer"
        title="Metamask"
      >
        Metamask
      </a>{" "}
      chrome extension. Load it with ETH through services that allow you to
      change your money into ETH like{" "}
      <a
        href="https://crypto.com/"
        target="_blank"
        rel="noreferrer"
        title="Cripto.com"
      >
        Cripto.com
      </a>{" "}
      or{" "}
      <a
        href="https://www.binance.com/"
        target="_blank"
        rel="noreferrer"
        title="Binance"
      >
        Binance
      </a>
      . Finally, approve the transaction on Metamask. And voilá!
      </p>
      <h2>How can I trade my NFTs?</h2>
      <p>
      Our NFTs adhere to the ERC-721 standard so you can trade them on platforms like OpenSea.
      </p>
      <h2>What wallet is issuing The Ephemeral Ethernal NFTs?</h2>
      <p>
        All NFTs are minted through the official{" "}
        <a
          href="https://opensea.io/accounts/EphemeralEthernal"
          target="_blank"
          rel="noreferrer"
          title="Ephemeral Ethernal wallet"
        >
          Ephemeral Ethernal wallet
        </a>{" "}
        with the agreement of every artist involved.
      </p>
      <h2>EPHEMERAL ETHERNAL</h2>
      <p>
      According to the ancient Egyptians, death was a process in which the soul separated from the body. As such, they believed that death stood as a period of change and transition into another existence. Since the body was understood as the home of the soul, there was a great concern with the preservation for eternity of the bodies of those who died.
      <br />
      <br />
      Over decades, artistic practice and intervention in public space became defined by its ephemerality and fugacity, organizing the unpredictability of its existence with the limits of legality, chance and the organic nature of urban contexts. The digital archive and its social proliferation came to transform both its character, as well as its purpose and complexion. The continuity, survival and proliferation of a transitive and finite art, propagates and immortalizes itself in a universe that is both eternal and, ironically, fleeting.
      <br />
      <br />
      The idea of the ephemeral eternal is in itself a precise contradiction, a paradox: if what is ephemeral defines what is transitory, its perpetuity is to eternalize the life of death. In days gone by, this would be a condition deified of its premise, but today it is the perfect illustration of unfilled time, paradoxical and immaterial-materialized in the virtual world we live in.
      <br />
      <br />
      We are then left with only one certainty, that unlike the ancient Egyptians, if the body holds the place of the soul, the future and eternity are nothing more than its inescapable inexistence.
      <br />
      <br />
      ±MaisMenos±
      </p>
    </section>
  );
}

export default HowDoesItWorks;
