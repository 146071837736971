import "./ScheduleArticle.scss";

export default function scheduleArticle({ drop }) {
  const { id, date, weekDay, artists } = drop;
  return (
    <article key={id}>
      <header>
        <div>
          <span>#{id}</span>
          <br />
          drop
        </div>
        <div>
          <span>{date}</span>
          <br />
          {weekDay}
        </div>
      </header>
      <ul>
        {artists.map((name, index) => (
          <li key={index}>{name}</li>
        ))}
      </ul>
    </article>
  );
}
