import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "./../../assets/styles/common.scss";
import "./Navigation.scss";

export default function Navigation() {
    const [open, setOpen] = useState(false);
    return (
        <nav className="wrapper">
            <button id="menuToggle" className={open ? "open" : "close"} onClick={() => setOpen(!open)}>
                <span></span>
                <span></span>
                <span></span>
            </button>
            <ul className={open ? "open" : "close"}>
                <li>
                    <NavLink to="/" exact className="">
                        new drops
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/previous-drops" exact className="">
                        previous drops
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/how-does-it-work" exact className="">
                        how does it work?
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/newsletter" exact className="">
                        newsletter
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}
