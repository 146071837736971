import "./DropRowArticle.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import dummy from "./../../assets/images/dummy.jpg";

export default function DropRowArticle({
  article,
  isRowActive,
  isFirstDropInList,
  hasFinished
}) {
  const { id, title, artist, image, url, price, finalBid, closed } = article;
  const auctionClosedStyle = closed ? "closed" : "";
  const finalImage = image || dummy;
  const finalPrice = Intl.NumberFormat("pt-PT").format(price);
  // const finalBidPrice = Intl.NumberFormat("pt-PT").format(finalBid);
  const finalBidPrice = finalBid;
  const dynamicActiveClass = isRowActive
    ? "dropRowArticle--active"
    : "dropRowArticle--inactive";
  const isFirstDropInListClass = isFirstDropInList && !!url ? "pointer" : "";
  const hasFinishedPointer = hasFinished ? "pointer" : "";

  function handleFirstRowInactiveClick() {
    if(hasFinished) window.open(url, "_blank","noreferrer");
    if (!isFirstDropInList && !!url) return;
    if (!isRowActive && isFirstDropInList && !!url) window.open(url, "_blank","noreferrer");
  }

  function displayPrice() {
    if (finalBidPrice === "-") {
      return '';
    } else {
      return finalBidPrice + 'ETH';
    }
  }

  function displayFinalBidHeader() {
    if (finalBidPrice === "-") {
      return '';
    } else {
      return <span className="bidStartsAt">final bid</span>;
    }
  }

  function bottom() {
    if (closed || hasFinished) {
      return (
        <span className="closed">
          <a href={url} target="blank">
            SOLD
          </a>
          <span>
            { displayFinalBidHeader() }
            { displayPrice() }
          </span>
        </span>
      );
    } else {
      return (
        <>
          {isRowActive && (
            <>
              <a href={url} target="blank">
                BID NOW
              </a>
              <span>
                <span className="bidStartsAt">bid starts at</span>
                {finalPrice} ETH
              </span>
            </>
          )}
        </>
      );
    }
  }

  function displayTitle() {
    if (title) {
      return (<h2>{title}</h2>);
    } else {
      return (<h2 style={{color: "transparent", opacity: "0"}}>.</h2>);
    }
  }

  return (
    <>
      <article
        className={`dropRowArticle ${auctionClosedStyle} ${dynamicActiveClass} ${isFirstDropInListClass} ${hasFinishedPointer}`}
        data-id={id}
        onClick={handleFirstRowInactiveClick}
      >
        <LazyLoadImage
          alt={`${title} - ${artist}`}
          height="358px"
          effect="blur"
          src={finalImage}
          width="100%"
        />
        {displayTitle()}
        <h3>{artist}</h3>
        <div className="bottom">{bottom()}</div>
      </article>
    </>
  );
}
