import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navigation from "./components/navigation/Navigation";
import { CurrentDrops, Drops, HowDoesItWorks, Newsletter } from "./pages";

function App() {
  return (
    <div className="fullPageHeight">
      <Router>
        <Navigation />
        <Switch>
          <Route path="/how-does-it-work">
            <HowDoesItWorks />
          </Route>
          <Route path="/newsletter">
            <Newsletter />
          </Route>
          <Route path="/previous-drops">
            <Drops />
          </Route>
          <Route path="/">
            <CurrentDrops />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
