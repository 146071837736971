import { useState, useEffect } from "react";
import fromUnixTime from "date-fns/fromUnixTime";

export default function MinutesCountDown({ endTime }) {
  const [phrase, setPhrase] = useState("");

  useEffect(() => {
    const myInterval = setInterval(() => {
      const now = new Date();
      const endDate = new Date(fromUnixTime(endTime));
      const days = parseInt((endDate - now) / (1000 * 60 * 60 * 24));
      const hours = parseInt((Math.abs(endDate - now) / (1000 * 60 * 60)) % 24);
      const minutes = parseInt((Math.abs(endDate.getTime() - now.getTime()) / (1000 * 60)) % 60);
      const seconds = parseInt((Math.abs(endDate.getTime() - now.getTime()) / 1000) % 60);
      setPhrase(`Ends in ${days}days ${hours}hours ${minutes}minutes ${seconds}seconds`);

      if (now > endDate) {
        clearInterval(myInterval);
        setPhrase("");
      }

    }, 1000);
  }, [endTime]);

  return <>{phrase}</>;
}
