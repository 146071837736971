import React, { useState, useEffect } from "react";
import "./NewDropTimer.scss";

export default function NewDropTimer({
    endTime,
    days,
    hours,
    minutes,
    seconds,
    initialText = 'ends',
    toggleActive,
}) {
    const [timerDays, setTimerDays] = useState();
    const [timerHours, setTimerHours] = useState();
    const [timerMinutes, setTimerMinutes] = useState();
    const [timerSeconds, setTimerSeconds] = useState();

    let interval;

    const startTimer = () => {
        const countDownDate = new Date(endTime).getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countDownDate - now;
            const days = Math.floor(distance / (24 * 60 * 60 * 1000));
            const hours = Math.floor(
                (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (distance % (60 * 60 * 1000)) / (1000 * 60)
            );
            const seconds = Math.floor((distance % (60 * 1000)) / 1000);

            if (distance < 0) {
                // Stop Timer
                toggleActive();
                clearInterval(interval.current);
            } else {
                // Update Timer
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
            }
        });
    };

    useEffect(() => {
        startTimer();
    });

    return (
        <div className="newDropTimer">
            <span>{initialText} IN </span>
            {days && (
                <>
                    <span className="timerNumber">{timerDays}</span>{" "}
                    <span>DAYS </span>
                </>
            )}
            {hours && (
                <>
                    <span className="timerNumber">{timerHours}</span>
                    <span>HOURS </span>
                </>
            )}
            {minutes && (
                <>
                    <span className="timerNumber">{timerMinutes}</span>
                    <span>MINUTES </span>
                </>
            )}
            {seconds && (
                <>
                    <span className="timerNumber">{timerSeconds}</span>
                    <span>SECONDS </span>
                </>
            )}
        </div>
    );
}
