import { useDB } from "../../data/useDB";
import ScheduleArticle from "./../../components/ScheduleArticle/ScheduleArticle";
import DropRow from "./../../components/DropRow/DropRow";

import UnderdogsLogo from "./../../assets/images/underdogs.svg";
import "./../../assets/styles/common.scss";
import "./Drops.scss";

function Drops() {
  const { isLoading, isError, data } = useDB();
  if (isLoading) return <header className="wrapper">loading...</header>;
  if (isError) return <header className="wrapper">please try again...</header>;
  const drops = data.data.rows;
  return (
    <>
      <header className="wrapper">
        <h1>EPHEMERAL | ETHERNAL</h1>
        <h2>A NFT group show</h2>
        curated by
        <br />
        <a
          href="https://www.under-dogs.net/"
          rel="noreferrer"
          target="_blank"
          title="UNDERDOGS"
        >
          <img src={UnderdogsLogo} alt="Underdogs" className="logo" />
        </a>
        <br />
        <p>
        Ephemeral Ethernal is the first NFT group show curated by Underdogs, including original works by a selection of 40 contemporary artists articulating exceptionally poignant and unique voices. Emerging from the growing debate surrounding the materiality, perpetuity and inherent value of artworks, along with the very nature of art itself, it represents a provocation: plunging into the future and questioning the structures that support our concept and experience of modernity.
        </p>
        <br/>
        <p>Composed exclusively of NFT-based works, Ephemeral Ethernal embodies the spirit of this new technology, upending the rules of digital ownership and clearing the way towards the end of ephemeral art, evolving or even elevating it into a new plain of existence.</p>
        <br/>
        <p>For 5 weeks, we bring you 5 drops of original NFT works by different artists, always on Sunday.</p>
        <br/>
        <p>This group show is powered by <a
          href="https://opensea.io/accounts/EphemeralEthernal"
          target="_blank"
          rel="noreferrer"
          title="OpenSea"
          className="underlinedLink"
        >OpenSea</a>.</p>
      </header>
      <section className="schedules">
        <div className="wrapper schedules-grid">
          {drops.map((drop) => (
            <ScheduleArticle key={drop.id} drop={drop} />
          ))}
        </div>
      </section>
      <section className="wrapper">
        {drops.sort((a, b) => a.orderNr - b.orderNr).map((dropRow, index) => (
          <DropRow key={dropRow.id} drop={dropRow} index={index} />
        ))}
      </section>
    </>
  );
}

export default Drops;
