import React, { useState, useMemo, useEffect, useRef } from "react";
import differenceInSeconds from "date-fns/differenceInSeconds";
import "./Timer.scss";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function Timer({ deadline, toggleActive }) {
  const ONE_DAY = 60 * 60 * 24;
  const ONE_HOUR = 60 * 60;
  const ONE_MINUTE = 60;
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const diffInSeconds = differenceInSeconds(deadline, currentTime);

  const getCoundown = () => {
    if (diffInSeconds <= 1) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    const days = Math.floor(diffInSeconds / ONE_DAY);
    const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
    const minutes = Math.floor(
      (diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE
    );
    const seconds =
      diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE;
    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  // eslint-disable-next-line
  const countdown = useMemo(getCoundown, [currentTime]);

  useInterval(() => {
    const now = new Date().getTime();
    setCurrentTime(now);
  }, 1000);

  useEffect(() => {
    if (currentTime > deadline) {
      toggleActive();
    }
  }, [currentTime, deadline, toggleActive])

  return (
    <>
      <span className="normalFontBold">DROP IN </span>
      <span>{countdown.days}</span>
      <span className="smallerFont"> days </span>
      <span>{countdown.hours}</span>
      <span className="smallerFont"> hours </span>
      <span>{countdown.minutes}</span>
      <span className="smallerFont"> minutes </span>
      <span>{countdown.seconds}</span>
      <span className="smallerFont"> seconds </span>
    </>
  );
}
