import { useState } from "react";
import axios from "axios";
import Timer from "./../Timer/Timer";
import { isEmailValid } from "./../../helpers";
import "./DropRowInactive.scss";

export default function DropRowInactive({ dateObject, toggleActive }) {
  const [email, setEmail] = useState("");
  const [output, setOutput] = useState({
    message: "",
    status: "",
  });

  const submitHandler = () => {
    if (isEmailValid(email)) {
      setOutput({ message: "", status: "" });
      const params = new URLSearchParams();
      params.append("email", email);
      axios.post("https://www.ephemeralethernal.com/api/", params).then(
        (response) => {
          setOutput({
            message: response.data.message,
            status: response.data.status,
          });
          if (response.data.status !== "error") {
            setEmail("");
          }
        },
        (error) => {
          setOutput({
            message: "an error occurred please try again!",
            status: "error",
          });
        }
      );
    }
  };

  function handleDisable() {
    return !isEmailValid(email);
  }

  return (
    <div className="timmer__newsletter">
      <div className="timmer__newsletter__inside">
        <Timer deadline={dateObject} toggleActive={toggleActive} />
        <div className="newsletterContainer">
          <h5>Sign up to the newsletter to get the latest updates:</h5>
          <div className="newsletter-form__container">
            <input
              type="email"
              placeholder="email"
              autoComplete="new-email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <button onClick={submitHandler} disabled={handleDisable()}>
              Sign Up
            </button>
            <div className={`message ${output.status}`}>{output.message}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
