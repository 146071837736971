// import underdogsLogoFooter from "./../../assets/images/underdogs-footer.svg";
import Instagram from "./../../assets/images/instagram.svg";
import Twitter from "./../../assets/images/twitter.svg";
import EphemeralLogo from "./../../assets/images/ephemeral.svg";
import "./Footer.scss";

export default function Footer() {
    return (
        <footer>
            <div className="wrapper footer__grid">
                <div>
                    <img
                        src={EphemeralLogo}
                        alt="Ephemeral Ethernal"
                        className="ephemeral_footer_logo"
                    />
                </div>
                <div>
                    <h6>CONTACT US:</h6>
                    <p>
                        <a
                            href="mailto:info@ephemeralethernal.com"
                            title="contact us"
                        >
                            info@ephemeralethernal.com
                        </a>
                    </p>
                </div>
                <div>
                    <h6>CONTACT US:</h6>
                    <ul>
                        <li>
                            <a
                                href="https://www.instagram.com/ephemeralethernal/"
                                title="ethernalephemeral instagram"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={Instagram}
                                    alt="ethernalephemeral instagram"
                                    width="35px"
                                />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://twitter.com/EphemeralEther1"
                                title="ethernalephemeral twitter"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={Twitter}
                                    alt="ethernalephemeral twitter"
                                    width="35px"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <p>
                        This release is powered by
                        <br />
                        <a
                            href="https://opensea.io/accounts/EphemeralEthernal"
                            title="OpenSea"
                        >
                            OpenSea
                        </a>
                    </p>
                </div>
            </div>
        </footer>
    );
}
