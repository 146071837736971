import "./NewDropArticle.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import dummy from "./../../assets/images/dummy.jpg";

export default function NewDropArticle({
    article,
    isRowActive,
    isFirstDropInList,
    hasFinished,
    started,
}) {
    const {
        id,
        title,
        artist,
        description,
        image,
        url,
        price,
        finalBid,
        closed,
    } = article;
    const auctionClosedStyle = closed ? "closed" : "";
    const finalImage = image || dummy;
    const finalPrice = Intl.NumberFormat("pt-PT").format(price);
    const finalBidPrice = finalBid;
    const dynamicActiveClass = isRowActive
        ? "newDropRowArticle--active"
        : "newDropRowArticle--inactive";
    const isFirstDropInListClass = isFirstDropInList && !!url ? "pointer" : "";
    const hasFinishedPointer = hasFinished ? "pointer" : "";

    function handleFirstRowInactiveClick() {
        window.open(url, "_blank", "noreferrer");
        // if (hasFinished) window.open(url, "_blank", "noreferrer");
        // if (!isFirstDropInList && !!url) return;
        // if (!isRowActive && isFirstDropInList && !!url)
        //     window.open(url, "_blank", "noreferrer");
    }

    function displayPrice() {
        if (finalBidPrice === "-") {
            return "";
        } else {
            return finalBidPrice + "ETH";
        }
    }

    function displayFinalBidHeader() {
        if (finalBidPrice === "-") {
            return "";
        } else {
            return <span className="bidStartsAt">final bid</span>;
        }
    }

    function bottom() {
        if (!started) {
            return (
                <>
                    <span className="goToTop">
                        <span className="bidStartsAt">bid starts at</span>
                        {finalPrice} ETH
                    </span>
                </>
            );
        }
        if (closed || hasFinished) {
            return (
                <span className="closed">
                    <a href={url} target="blank">
                        SOLD
                    </a>
                    <span>
                        {displayFinalBidHeader()}
                        {displayPrice()}
                    </span>
                </span>
            );
        } else {
            return (
                <>
                    {isRowActive && (
                        <>
                            <a href={url} target="blank">
                                BID NOW
                            </a>
                            <span>
                                <span className="bidStartsAt">
                                    bid starts at
                                </span>
                                {finalPrice} ETH
                            </span>
                        </>
                    )}
                </>
            );
        }
    }

    return (
        <>
            <article
                className={`newDropRowArticle ${auctionClosedStyle} ${dynamicActiveClass} ${isFirstDropInListClass} ${hasFinishedPointer} cursorPointer`}
                data-id={id}
                onClick={handleFirstRowInactiveClick}
            >
                <div className="imageWrapper">
                    <LazyLoadImage
                        alt={`${title} - ${artist}`}
                        effect="blur"
                        src={finalImage}
                        width="100%"
                    />
                </div>
                <h1>{title}</h1>
                <p>{description}</p>
                <h3>{artist}</h3>
                <div className="bottom">{bottom()}</div>
            </article>
        </>
    );
}
