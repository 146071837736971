import axios from "axios";
import { useQuery } from "react-query";

export function useDB() {
    return useQuery("database", () => axios.get("bd.json"));
}

export function useNewDropsDB() {
    return useQuery("database2", () => axios.get("bdNewDrops.json"));
}
