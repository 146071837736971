export default function NewDropHiddenText() {
    return (
        <>
            The auction will begin wednesday{" "}
            <strong>6 October at 7pm (GMT+1)</strong> and last for a total of{" "}
            <strong>24 hours</strong>. Bids begin at 0,1 ETH and all four NFTs
            will be released at the same time, launched through the Ephemeral
            Ethernal wallet.
            <br />
            <br />
            Created as an extension of his current solo exhibition at Underdogs
            Gallery,{" "}
            <a
                href="https://www.under-dogs.net/blogs/exhibitions/maismenos-solo-exhibition/"
                target="_blank"
                rel="noreferrer"
                title="Yes or No Future"
                class="underline"
            >
                Yes or No Future
            </a>
            , these digital pieces are animated versions of a selection of works
            contained within the show, questioning the current social,
            political, economic, cultural and environmental state of our world.
        </>
    );
}
