import fromUnixTime from "date-fns/fromUnixTime";
import DropRowInactive from "./../DropRowInactive/DropRowInactive";
import DropRowArticle from "./../DropRowArticle/DropRowArticle";
import MinutesCountDown from "./../MinutesCountDown/MinutesCountDown";
import "./DropRow.scss";
import { useState } from "react";

export default function DropRow({ drop, index }) {
  const { id, drops, isActive: isDropActive, startTime, endTime, startTimeText, hasFinished } = drop;
  const isFirstDropInList = index === 0 ? true : false;
  const [isActive, toggleActive] = useState(isDropActive);
  // const [hasFinished, toggleHasFinished] = useState(hasDropFinished);
  if (!drops) return false;

  if (!isActive) {
    const dateObject = fromUnixTime(startTime);
    return (
      <>
        <h6 className="dropRow__title">
          #{id} DROP <span className="startTimeText">{startTimeText}</span>
        </h6>
        <section className="dropRow">
          <DropRowInactive dateObject={dateObject} toggleActive={() => toggleActive(!isActive)} />
          {drops.map((article) => (
            <DropRowArticle
              key={article.id}
              article={article}
              isRowActive={isActive}
              isFirstDropInList={isFirstDropInList}
            />
          ))}
        </section>
      </>
    );
  }

  if (isActive) {
    return (
      <>
        <h6 className="dropRow__title">
          #{id} DROP{" "}
          {!hasFinished && (<span className="drop__endsIn">
              <MinutesCountDown endTime={endTime} />
          </span>)}
        </h6>
        <section className="dropRow">
          {drops.map((article) => (
            <DropRowArticle
              key={article.id}
              article={article}
              isRowActive={isActive}
              hasFinished={hasFinished}
            />
          ))}
        </section>
      </>
    );
  }
}
