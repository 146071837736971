import { useState } from "react";
import axios from "axios";
import { isEmailValid } from "./../../helpers";
import "./Newsletter.scss";

function Newsletter() {
  const [email, setEmail] = useState("");
  const [output, setOutput] = useState({
    message: "",
    status: "",
  });

  const submitHandler = () => {
    if (isEmailValid(email)) {
      setOutput({ message: "", status: "" });
      const params = new URLSearchParams();
      params.append("email", email);
      axios.post("https://www.ephemeralethernal.com/api/", params).then(
        (response) => {
          setOutput({
            message: response.data.message,
            status: response.data.status,
          });
          if (response.data.status !== "error") {
            setEmail("");
          }
        },
        (error) => {
          setOutput({
            message: "an error occurred please try again!",
            status: "error",
          });
        }
      );
    }
  };

  function handleDisable() {
    return !isEmailValid(email);
  }

  return (
    <section className="wrapper newsletter__page">
      <h2>Ephemeral | EtHernal</h2>
      <p>don’t miss the next drop:</p>
      <div className="newsletter-form__container">
        <input
          type="email"
          placeholder="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <button onClick={submitHandler} disabled={handleDisable()}>
          Sign Up
        </button>
        <div className={`message ${output.status}`}>{output.message}</div>
      </div>
    </section>
  );
}

export default Newsletter;
