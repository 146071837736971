import { useState } from "react";
import { useNewDropsDB } from "../../data/useDB";
import styled from "styled-components";
import NewDropTimer from "./../../components/newDropTimer/NewDropTimer";
import NewDropArticle from "./../../components/NewDropArticle/NewDropArticle";
import NewDropHiddenText from "../../components/NewDropHiddenText/NewDropHiddenText";
import MaisMenosBg from "./../../assets/images/MaisMenosBg.jpg";

function CurrentDrops() {
    const [readMore, setReadMore] = useState(false);
    const [showTimers, setShowTimers] = useState(true);
    const [isActive, toggleActive] = useState(false);
    const { isLoading, isError, data } = useNewDropsDB();
    
    if (isLoading) return <header className="wrapper">loading...</header>;
    if (isError) return <header className="wrapper">please try again...</header>;
    
    const campaign = data.data.campaign;
    const drops = data.data.drops;

    function renderTimers() {
        if(showTimers) {
            if(!isActive) {
                return <NewDropTimer endTime={campaign.startTime} initialText="starts" hours minutes seconds toggleActive={() => toggleActive(!isActive)} />;
            } else {
                return <NewDropTimer endTime={campaign.endTime} initialText="ends" hours minutes seconds toggleActive={() => setShowTimers(false)} />;
            }
        }
        else {
            return <></>;
        }
    }

    return (
        <>
            <HeaderSection>
                <div className="wrapper">
                    <H2>EPHEMERAL | ETHERNAL</H2>
                    <Presents>presents</Presents>
                    <H1>
                        <span>INDIVI-DUALISM </span>
                        <span className="break">
                            <br />
                        </span>
                        <span>SERIES by </span>
                        <span className="break">
                            <br />
                        </span>
                        <strong>±MAISMENOS±</strong>
                    </H1>
                    <Text className="wrapper">
                        Ephemeral Ethernal is releasing{" "}
                        <strong>a series of four NFT’s by ±MaisMenos±</strong>,
                        the intervention art project by Portuguese visual artist
                        and graphic designer Miguel Januário.
                        {!readMore && (
                            <ReadMore onClick={() => setReadMore(true)}>
                                [ read more ]
                            </ReadMore>
                        )}
                        <br />
                        <br />
                        <span className="hiddenTextMobile">
                            {readMore && <NewDropHiddenText />}
                        </span>
                        <span className="hiddenTextDesktop">
                            <NewDropHiddenText />
                        </span>
                    </Text>
                </div>
            </HeaderSection>
            <Section>
                <SectionCounter>
                    <h6>DROP</h6>
                    {renderTimers()}
                </SectionCounter>
                <ArticlesWrapper className="wrapper">
                    {drops.map((article, index) => (
                        <NewDropArticle
                            key={article.id}
                            article={article}
                            isRowActive={isActive}
                            isFirstDropInList={false}
                            started={isActive}
                            hasFinished={!showTimers}
                        />
                    ))}
                </ArticlesWrapper>
            </Section>
        </>
    );
}

const HeaderSection = styled.section`
    font-family: "CambonLight", sans-serif;
    text-align: center;
    margin-bottom: 2rem;
`;

const Presents = styled.p`
    font: normal normal 300 1.5rem "CambonLight";
    @media screen and (min-width: 992px) {
        font: normal normal 300 1.5rem "CambonLight";
    }
`;

const Text = styled.p`
    margin-top: 3rem;
    margin-bottom: 1rem;
    font: normal normal 300 1.31rem "CambonLight";

    .hiddenTextMobile {
        display: initial;
    }

    .hiddenTextDesktop {
        display: none;
    }

    @media screen and (min-width: 992px) {
        .hiddenTextMobile {
            display: none;
        }

        .hiddenTextDesktop {
            display: initial;
        }
    }
`;

const H1 = styled.h1`
    font: normal normal 300 3.5rem "CambonLight";
    strong {
        font: normal normal 600 3.5rem "CambonLight";
    }

    @media screen and (min-width: 992px) {
        font: normal normal 300 3.75rem "CambonLight";
        strong {
            font: normal normal 600 3.75rem "CambonLight";
        }
    }

    @media screen and (min-width: 992px) {
        .break {
            display: none;
        }
    }
`;

const H2 = styled.h2`
    font: normal normal 300 2rem "CambonLight", sans-serif;

    @media screen and (min-width: 992px) {
        font: normal normal 300 2.5rem "CambonLight", sans-serif;
    }
`;

const Section = styled.section`
    background-image: url(${MaisMenosBg});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
`;

const SectionCounter = styled.section`
    color: white;
    text-align: center;
    margin-top: 8rem;
    margin-bottom: 5rem;

    h6 {
        font-family: "ABCFavoritMono";
        font-size: 7.43rem;
        letter-spacing: 2px;
    }

    p {
        font-size: 1.31rem;
    }
`;

const ArticlesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    margin-bottom: 15.87rem;
    @media screen and (min-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 1440px) {
        grid-template-columns: repeat(4, 1fr);
    }
`;

const ReadMore = styled.button`
    background: none;
    border: none;
    color: black;
    display: initial;
    @media screen and (min-width: 992px) {
        display: none;
    }
`;

export default CurrentDrops;
